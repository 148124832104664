import React from "react";
import PageBuilderArtistStatement from "./pageBuilderArtistStatement";
import PageBuilderMasterProject  from "./pageBuilderMasterProject"
import PageBuilderImage from "./pageBuilderImage";
import PageBuilderYoutube from "./pageBuilderYoutube";

function PageBuilder(props) {
  const { type, pageBuilder, _rawPageBuilder } = props;

  // Load the right component, based on the _type from Sanity
  const Components = {
    pageBuilderArtistStatement: PageBuilderArtistStatement,
    pageBuilderMasterProject: PageBuilderMasterProject,
    pageBuilderImage: PageBuilderImage,
    pageBuilderYoutube: PageBuilderYoutube
  };

  // 'raw' content needs to be passed in for the PortableText Component
  return pageBuilder.map((block, index) => {
    if (Components[block._type]) {
      return React.createElement(Components[block._type], {
        key: block._key,
        block: block,
        type,
        raw: _rawPageBuilder[index]
      });
    }
    return null
  });
}

export default PageBuilder;
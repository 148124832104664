import React from "react";
// import PortableText from "./portableText";

const PageBuilderMasterProject = ({ block, raw, index }) => {
    const { title, media, material, size, year } = block;
    const text = toPlainText(raw.body)
    const markup = { __html: text}

  return (
    <section>
        <h3>master project<span> → </span></h3>
        {title && <p>{title}<span> → </span></p>}
        {media && <p>{media}<span> → </span></p>}
        {material && <p>{material}<span> → </span></p>}
        {size && <p>{size}<span> → </span></p>}
        {year && <p>{year}<span> → </span></p>}
        <p dangerouslySetInnerHTML={markup}></p>
      {/* <PortableText blocks={raw.body} /> */}
    </section>
  );
};

export default PageBuilderMasterProject;

function toPlainText(blocks = []) {
    return blocks
      // loop through each block
      .map(block => {
        // if it's not a text block with children, 
        // return nothing
        if (block._type !== 'block' || !block.children) {
          return ''
        }
        // loop through the children spans, and join the
        // text strings
        return block.children.map(child => child.text).join('')
      })
      // join the paragraphs leaving split by two linebreaks
      .join('<span> ¶ </span>')
  }
import React, { useEffect } from "react"
import { graphql } from "gatsby"
import PageBuilder from "../components/pageBuilder";
import SEO from "../components/seo"
import { SRLWrapper } from "simple-react-lightbox";

export default function PageArtist(props) {
  const post = props.data.sanityArtist
  const title = post.firstName + " " + post.lastName;
  const options = {
    settings: {
      disablePanzoom: true,
    },
    caption: {},
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showFullscreenButton: false,
    },
    thumbnails: {},
    progressBar:{}
  }

  useEffect(() => {
    props.onClick();
  }, []);

  return (
    <div className="accordion__content"> 
    <SEO dynTitle={title}/>   
      <h2 className="artist__name">
        {post.firstName} {post.lastName}
      </h2>
      <ul className='artist__profile'>
        <li><span>→ </span>{post.field.professor}</li>
        <li><span>→ </span>{/* <Link to="/" state={{fromLocation: 'fromArtist'}}> */}{post.field.fieldNameGer}{/* </Link> */}</li>
        {post.url && <li><span>→ </span><a rel="noreferrer" href={post.url} target="_blank">Website</a></li>}
        {post.mailAddress && <li><span>→ </span><a href={"mailto:" + post.mailAddress}>Kontakt</a></li>}
      </ul>
      <SRLWrapper options={options}>
      <PageBuilder pageBuilder={props.data.sanityArtist.pageBuilder} _rawPageBuilder={props.data.sanityArtist._rawPageBuilder} />
      </SRLWrapper>
      <div className='artist__caption'>
      <ol>
      {post.pageBuilder.map(( block ) => (
        block._type === 'pageBuilderImage' && block.visibility && <li key={block._key}>{block.caption}</li>
      ))}
      </ol>
      </div>
    </div>
  )
}

export const query = graphql`
  query($slug: String!) {
    sanityArtist(slug: { current: { eq: $slug } }) {
      firstName
      lastName
      mailAddress
      url
      field {
        fieldNameGer
        professor
      }
      previewImage {
        asset {
            fluid {
              ...GatsbySanityImageFluid
            }
        }
      }
      ...PageBuilder
    }
  }
`

import React from "react";
import Img from "gatsby-image";

const PageBuilderImage = ({ block, raw, index }) => {
  const { image, alt, caption, visibility } = block;

  return (
    <div>
      {visibility && <Img className='pageBuilderImage' fluid={image.asset.fluid} title={caption} alt={alt} />}
    </div>
  );
};

export default PageBuilderImage;